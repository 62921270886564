<template>
  <div class="modal">
    <v-snackbar v-model="snackbar" multi-line timeout="4000"
      >Gültiger Lizenzschlüssel
    </v-snackbar>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card :disabled="loading">
          <v-card-title>
            <span class="headline">Lizenzschlüssel eingeben</span>
          </v-card-title>
          <v-card-text v-if="!showSuccess">
            <div class="body-2">
              Wir prüfen Deinen Lizenzschlüssel auf Gültigkeit. Kontaktiere den
              Support, wenn Du weitere Lizenzschlüssel benötigst oder Probleme
              auftreten.
            </div>
            <v-spacer style="height: 20px"></v-spacer>
            <v-form v-model="formIsValid" @submit.prevent>
              <v-text-field
                v-model.trim="licenseKey"
                outlined
                label="Lizenzschlüssel*"
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-form>
            <small>* Erforderlich</small>
          </v-card-text>
          <v-card-text v-if="errorMsg !== ''">
            <v-alert dense text type="error"> {{ errorMsg }} </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="$emit('close')">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!formIsValid"
              :loading="loading"
              depressed
              color="primary"
              @click="checkLicenseKey()"
            >
              Weiter
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { functions } from "@/firebase";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Ist erforderlich.",
      },
      licenseKey: "",
      formIsValid: false,
      loading: false,
      showSuccess: false,
      errorMsg: "",
      dialog: true,
      snackbar: false,
    };
  },
  methods: {
    checkLicenseKey() {
      this.loading = true;
      this.errorMsg = "";
      setTimeout(this.verifyLicenseKey, 1500);
    },
    verifyLicenseKey() {
      const callFunction = functions.httpsCallable(
        "organization-checkLicenseKey"
      );
      callFunction({ licenseKey: this.licenseKey })
        .then((result) => {
          console.log(result.data);
          if (result.data.isValid) {
            this.showSuccess = true;
            this.dialog = false;
            this.snackbar = true;
            this.loading = false;
            this.$router.push({
              name: "organization-new",
              query: { licenseKey: this.licenseKey },
            });
          } else {
            this.errorMsg = "Fehler: Dieser Lizenzschlüssel ist ungültig.";
            this.loading = false;
          }
        })
        .catch((err) => {
          // handle error
          console.error(err.errorMsg);
          console.error(err);
          this.errorMsg = err.message;
          this.loading = false;
        });
    },
  },
};
</script>
